<template>
  <v-app>
    <tev-navbar backTo="/" title="" />
    <v-content>
      <v-layout row wrap pt-4>
        <v-flex xs12>
          <v-form ref="form" class="form-sm" @submit.prevent="resetPassword">
            <v-container>
              <tev-pagetitle title="Change Password" subtitle="" />
              <v-layout row wrap>
                <v-flex xs12 sm12>
                  <v-text-field xs12 v-model="currentPassword" @click:append="toggleCurrent" :append-icon="currentIcon" :type="currentType" label="Current Password" :autofocus="$isDesktop()" :rules="rules.password" required box></v-text-field>
                </v-flex>
                <v-flex xs12 sm12>
                  <v-text-field xs12 v-model="newPassword" @click:append="toggleNew" :append-icon="newIcon" :type="newType" label="New Password" :rules="rules.password" required box></v-text-field>
                </v-flex>
                <v-flex xs12 sm12>
                  <v-text-field xs12 v-model="confirmPassword" @click:append="toggleConfirm" :append-icon="confirmIcon" :type="confirmType" label="Confirm Password" :rules="rules.confirmPassword" required box></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 mb-5>
                  <v-btn type="submit" color="primary" large block depressed @click="resetPassword">
                    Continue
                  </v-btn>
                </v-flex>
                <v-flex xs12 sm12 mb-5>
                  <p class="black--text">We strongly recommend the use of complex passwords.</p>
                  <p>A good password should have the following minimum characteristics:</p>
                  <ul>
                    <li>At least 8 characters Contain upper case letters</li>
                    <li>Contain lower case letters</li>
                    <li>Contain numeric characters</li>
                    <li>Contain special characters such as @ and $</li>
                    <li>Do not contain personal information such as names or birthdays</li>
                  </ul>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
          <v-snackbar v-model="snackbar" :bottom="true" :left="true">
            {{ snackbarText }}
            <v-btn color="red" flat @click="snackbar = false">
              Close
            </v-btn>
          </v-snackbar>
        </v-flex>
      </v-layout>
    </v-content>
  </v-app>
</template>
<script>
import {
  mapGetters
} from 'vuex'
import Validator from '../libs/validator'

export default {
  data() {
    const _this = this;
    const validator = new Validator();
    return {
      loading: false,
      currentPassword: null,
      newPassword: null,
      confirmPassword: null,

      currentType: 'password',
      currentIcon: 'remove_red_eye',
      newType: 'password',
      newIcon: 'remove_red_eye',
      confirmType: 'password',
      confirmIcon: 'remove_red_eye',

      snackbar: false,
      snackbarText: '',

      rules: {
        password: [
          validator.rules.required("You must complete the required fields."),
          validator.rules.minLength(8, "Your password must contain no less than 8 characters."),
          validator.rules.maxLength(100, "Your password should not contain more than 100 characters."),
        ],
        confirmPassword: [
          validator.rules.matches(_this, "newPassword", "The passwords you entered do not match.")
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser'
    })
  },
  methods: {
    alertSuccess(message) {
      this.snackbarText = message
      this.snackbar = true
    },
    alertError(message) {
      this.snackbarText = message
      this.snackbar = true
    },
    getType(el) {
      return el == 'text'? 'password' : 'text'
    },
    getIcon(el) {
      return el == 'text'? 'more_horiz' : 'remove_red_eye'
    },
    toggleCurrent() {
      this.currentType = this.getType(this.currentType)
      this.currentIcon = this.getIcon(this.currentType)
    },
    toggleNew() {
      this.newType = this.getType(this.newType)
      this.newIcon = this.getIcon(this.newType)
    },
    toggleConfirm() {
      this.confirmType = this.getType(this.confirmType)
      this.confirmIcon = this.getIcon(this.confirmType)
    },
    resetPassword() {
      const _this = this

      if(!this.$refs.form.validate()) {
        return;
      }

      if (this.currentPassword == this.newPassword) {
        return this.alertError("Your new password is the same as the current password.")
      }

      if (!this.newPassword.match(/[a-z]+/) || !this.newPassword.match(/[A-Z]+/) || !this.newPassword.match(/[0-9]+/)) {
        return this.alertError("Your password must contain numbers and letters.")
      }

      this.$http.post('/manager/change-password ', {
          currentPassword: this.currentPassword,
          newPassword: this.newPassword
        })
        .then(() => {
          _this.emailsent = true
          _this.alertSuccess("Your password has been saved successfully.")
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e)
          _this.alertError("We have not been able to change your password.")
        })

    }
  }
}
</script>
<style>
</style>