var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("tev-navbar", { attrs: { backTo: "/", title: "" } }),
      _c(
        "v-content",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "", "pt-4": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      staticClass: "form-sm",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.resetPassword.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c("tev-pagetitle", {
                            attrs: { title: "Change Password", subtitle: "" },
                          }),
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm12: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      xs12: "",
                                      "append-icon": _vm.currentIcon,
                                      type: _vm.currentType,
                                      label: "Current Password",
                                      autofocus: _vm.$isDesktop(),
                                      rules: _vm.rules.password,
                                      required: "",
                                      box: "",
                                    },
                                    on: { "click:append": _vm.toggleCurrent },
                                    model: {
                                      value: _vm.currentPassword,
                                      callback: function ($$v) {
                                        _vm.currentPassword = $$v
                                      },
                                      expression: "currentPassword",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm12: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      xs12: "",
                                      "append-icon": _vm.newIcon,
                                      type: _vm.newType,
                                      label: "New Password",
                                      rules: _vm.rules.password,
                                      required: "",
                                      box: "",
                                    },
                                    on: { "click:append": _vm.toggleNew },
                                    model: {
                                      value: _vm.newPassword,
                                      callback: function ($$v) {
                                        _vm.newPassword = $$v
                                      },
                                      expression: "newPassword",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm12: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      xs12: "",
                                      "append-icon": _vm.confirmIcon,
                                      type: _vm.confirmType,
                                      label: "Confirm Password",
                                      rules: _vm.rules.confirmPassword,
                                      required: "",
                                      box: "",
                                    },
                                    on: { "click:append": _vm.toggleConfirm },
                                    model: {
                                      value: _vm.confirmPassword,
                                      callback: function ($$v) {
                                        _vm.confirmPassword = $$v
                                      },
                                      expression: "confirmPassword",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm12: "", "mb-5": "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        type: "submit",
                                        color: "primary",
                                        large: "",
                                        block: "",
                                        depressed: "",
                                      },
                                      on: { click: _vm.resetPassword },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Continue\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm12: "", "mb-5": "" } },
                                [
                                  _c("p", { staticClass: "black--text" }, [
                                    _vm._v(
                                      "We strongly recommend the use of complex passwords."
                                    ),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      "A good password should have the following minimum characteristics:"
                                    ),
                                  ]),
                                  _c("ul", [
                                    _c("li", [
                                      _vm._v(
                                        "At least 8 characters Contain upper case letters"
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v("Contain lower case letters"),
                                    ]),
                                    _c("li", [
                                      _vm._v("Contain numeric characters"),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        "Contain special characters such as @ and $"
                                      ),
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        "Do not contain personal information such as names or birthdays"
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-snackbar",
                    {
                      attrs: { bottom: true, left: true },
                      model: {
                        value: _vm.snackbar,
                        callback: function ($$v) {
                          _vm.snackbar = $$v
                        },
                        expression: "snackbar",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.snackbarText) +
                          "\n          "
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "red", flat: "" },
                          on: {
                            click: function ($event) {
                              _vm.snackbar = false
                            },
                          },
                        },
                        [_vm._v("\n            Close\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }